import React, { useEffect, useContext, useState } from "react";

import { AppContext } from '@/contexts/AppContext';

import { QualificationContext } from '@/contexts/QualificationContext';

import Result from '@/components/Form/parts/Result';
import Question from '@/components/Form/parts/Question';

const Questions = () => {
	const { 
		setIsLoading,
		isLoading
	} = useContext(AppContext);

	const { 
		qualifications,
		qualificationId,
		setQualificationId,
		setAnswers,
	} = useContext(QualificationContext);

	const [ interviewNo, setInterviewNo ] = useState(0);
	const [ questionNo, setQuestionNo ] = useState(0);
	const [ isInterview, setIsInterview ] = useState(false)
	const [ result, setResult ] = useState(false)

	const [ disqualifications, setDisqualifications ] = useState({})
	const [ individualQualifications, setIndividualQualifications ] = useState({})
	const [ points, setPoints ] = useState({})
	const [ female, setFemale ] = useState(false)

	const qualification = qualifications.find(x => x.id === qualificationId)
	const interview = qualification.interview ? qualification.interview.questions : false;
	const questions = qualification.questions;

	const goNext = (question) => {
		setIsLoading(true)

		// console.log(question)
		// console.log(points)
		// console.log(points[question.id])
		if( (question.female_points_to_pass || question.male_points_to_pass) && !points[question.id]) {
			setPoints({
				...points,
				[question.id]: {
					title: question.text,
					female_points_to_pass: question.female_points_to_pass,
					male_points_to_pass: question.male_points_to_pass,
					value: {
						0: 0
					}
				}
			})
		}
		
		if ( isInterview ) {
			if ( interviewNo === interview.length - 1) {
				setIsInterview(false);
			} else {
				setInterviewNo(interviewNo + 1);
			}
		} else {
			
			if ( questionNo === questions.length - 1) {
				setResult(true)
			} else {
				setQuestionNo(questionNo + 1) 
			}
		}
		
	}

	const goBack = () => {
		setIsLoading(true)
		if ( isInterview ) {
			if ( interviewNo === 0) {
				setQualificationId(null)
				setAnswers({})
			} else {
				setInterviewNo(interviewNo - 1)
			}
		} else {

			if ( questionNo === 0) {
				if (interview) {
					setIsInterview(true)
				} else {
					setQualificationId(null)
					setAnswers({})
				}
				
				
			} else {
				setQuestionNo(questionNo - 1)
			}
		}
	}

	const handleChange = (event, question, answer) => {
		
		if (event.target.type === 'radio') {
			setDisqualifications({
				...disqualifications,
				[question.id]: answer.disqualification
			})

			setIndividualQualifications({
				...individualQualifications,
				[question.id]: answer.individual_qualification
			})
		} else {
			if ( event.target.checked ) {
				setDisqualifications({
					...disqualifications,
					[question.id]: { 
						...disqualifications[question.id], 
						[answer.id]: answer.disqualification 
					} 
				})

				setIndividualQualifications({
					...individualQualifications,
					[question.id]: { 
						...individualQualifications[question.id], 
						[answer.id]: answer.individual_qualification
					} 
				})

				if( question.female_points_to_pass || question.male_points_to_pass) {

					if ( points[question.id] ) {
						setPoints({
							...points,
							[question.id]: {
								title: question.text,
								female_points_to_pass: question.female_points_to_pass,
								male_points_to_pass: question.male_points_to_pass,
								value: {
									...points[question.id]['value'],
									[answer.id]: answer.points
								}
							}
						})
					} else {
						setPoints({
							...points,
							[question.id]: {
								title: question.text,
								female_points_to_pass: question.female_points_to_pass,
								male_points_to_pass: question.male_points_to_pass,
								value: {
									[answer.id]: answer.points
								}
							}
						})
					}

					if (answer.criteria === 'female') setFemale(true)
				}



			} else {
				setDisqualifications({
					...disqualifications,
					[question.id]: { 
						...disqualifications[question.id], 
						[answer.id]: false 
					} 
				})

				setIndividualQualifications({
					...individualQualifications,
					[question.id]: { 
						...individualQualifications[question.id], 
						[answer.id]: false
					} 
				})

				if( question.female_points_to_pass || question.male_points_to_pass) {

					setPoints({
						...points,
						[question.id]: {
							title: question.text,
							female_points_to_pass: question.female_points_to_pass,
							male_points_to_pass: question.male_points_to_pass,
							value: {
								...points[question.id]['value'],
								[answer.id]: 0
							}
						}
					})

					if (answer.criteria === 'female') setFemale(false)
				}
			}
		}

		
	}

	const status = () => {
		if ( Object.values(disqualifications).includes(true) ) {
			return 'rejected'
		}

		if ( Object.values(individualQualifications).includes(true) ) {
			return 'individual'
		}

		if ( female ) {
			if ( Object.values(points).map(item => item.female_points_to_pass <= Object.values(item.value).reduce((a, b) => a + b, 0)).includes(false) ) {
				return 'rejected'
			}
		} else {
			if ( Object.values(points).map(item => item.male_points_to_pass <= Object.values(item.value).reduce((a, b) => a + b, 0)).includes(false) ) {
				return 'rejected'
			}
		}

		return 'classified'

	}

	useEffect(() => {
		setIsInterview(Boolean(interview))
	}, [])

	useEffect(() => {
		setIsLoading(false)
	}, [interviewNo, questionNo, interview, isInterview])
	
	
	return (
		<>
		{ result ? (
			<Result
				status={ status() }
				points={ points }
				title={qualification.name}
			/>
		) : (
			<>
			{!isLoading && <Question
				key={isInterview ? interview[interviewNo] : questions[questionNo]}
				title={isInterview ? `Wywiad ${interviewNo + 1}/${interview.length}` : `Kryterium kwalifikacyjne ${questionNo + 1}/${questions.length}`}
				questionNo={isInterview ? interviewNo + 1 : questionNo + 1}
				question={isInterview ? interview[interviewNo] : questions[questionNo]}
				isInterview={isInterview}
				goNext={goNext}
				goBack={goBack}
				handleChange={handleChange}
			/>}
			</>
		)}
		</>
	)
	
}

export default Questions;