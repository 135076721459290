import React, { useEffect, useState, useContext } from "react";
import localForage from 'localforage';

import api from "@/services/api";

import { AppContext } from '@/contexts/AppContext';
import QualificationContextProvider from '@/contexts/QualificationContext';

import ChooseQualification from '@/components/Form/parts/ChooseQualification';
import Questions from '@/components/Form/parts/Questions';
import Patient from '@/components/Form/parts/Patient';

const Form = () => {
	const { 
		token,
		setIsLoading,
	} = useContext(AppContext);

	const [ qualificationId, setQualificationId ] = useState(null) 
	const [ answers, setAnswers ] = useState({}) 
	const [ patientContinue, setPatientContinue ] = useState(false) 
	const [ patient, setPatient ] = useState({})
	const [ entry, setEntry ] = useState({})
	const [ entries, setEntries ] = useState([])
	const [ qualifications, setQualifications ] = useState([])

	const [ isReadyToSend, setIsReadyToSend ] = useState(false)

	const [ isSending, setIsSending ] = useState(false)

	const sendQualification = (type) => {
		if (type === 'file') {
			setIsReadyToSend(true);
		} else {
			if (entries) {
				setEntries([...entries, entry]);
			} else {
				setEntries([entry]);
			}
			setPatientContinue(false);
			setQualificationId(null)
			setAnswers({})
			setPatient({})
		}
	}

	const continueQualification = () => {
		setPatientContinue(true);
	}

	useEffect(() => {
		if (entries && entries.length) {
			setIsSending(true);
			
			api.post('api/v1/qualifications/result', entries, { headers: {
				Authorization: `Bearer ${token}`
			}})
				.then(() => {
					setEntries([]);
					setIsSending(false);
					
					localForage.getItem('entries').then((entriesList) => {
						if (entriesList) localForage.removeItem('entries');
					});
				})
				.catch(() => {
					localForage.setItem('entries', entries).then(() => {
						setIsSending(false);
					});
				});
		}
	}, [entries])

	useEffect(() => {
		console.log('Wysyłanie danych:');
		console.log(entries);
	}, [isSending])

	useEffect(() => {
		setIsLoading(true);

		api.get('api/v1/qualifications', { headers: {
			Authorization: `Bearer ${token}`
		}})
			.then(({data: QualificationData}) => {
				localForage.setItem('qualifications', QualificationData).then(() => {
					setIsLoading(false);
					setQualifications(QualificationData)
				});
			})
			.catch(() => {
				setIsLoading(false);
				localForage.getItem('qualifications').then((qualificationsList) => setQualifications(qualificationsList))
			})

		localForage.getItem('entries').then((entriesList) => setEntries(entriesList))
	}, [])

	useEffect(() => {
		setEntry({
			...answers,
			...patient
		})
	}, [answers, patient])

	useEffect(() => {
		if (isReadyToSend) {
			if (entries) {
				setEntries([...entries, entry]);
			} else {
				setEntries([entry]);
			}
			
			setIsReadyToSend(false);
		}
	}, [entry])

	useEffect(() => {
		if (!isReadyToSend) {
			setPatientContinue(false);
			setQualificationId(null)
			setAnswers({})
			setPatient({})
		}
	}, [isReadyToSend])

	return (
		<QualificationContextProvider 
			qualifications={ qualifications }
			qualificationId={ qualificationId } 
			setQualificationId={ setQualificationId }
			answers={answers}
			setAnswers={setAnswers}
			sendQualification={sendQualification}
			continueQualification={continueQualification}
		>	
			
			{!qualificationId && <ChooseQualification/>}
			{qualificationId && (
				<>
					{patientContinue ? (
						<Patient setPatient={setPatient}/>
					) : (
						<Questions/>
					)}
				</>
			)}
		</QualificationContextProvider>
	);
};


export default Form; 