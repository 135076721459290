import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  primary: '#004493',
  secondary: '#ffffff',
  success: '#45B829',
  error: '#FF5722',
  info: '#a2a2a2',

  black: '#000',
  gray: '#a2a2a2'
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    success: {
      main: colors.success,
    },
    error: {
      main: colors.error,
    },
    textSecondary: {
      main: colors.gray,
    }
  },
  // typography: {

  // },
  // shadows: [],

  props: {
    MuiTypography: {
      variantMapping: {
        subtitle1: 'h1',
        subtitle2: 'h2',
      },
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
      }
    },

    MuiToolbar: {
      root: {
        fontWeight: 700,
        fontSize: '1.2rem'
      }
    },

    MuiTypography: {

      subtitle1: {
        color: colors.black,
        fontWeight: 500,
        fontSize: '1.5rem'
      },
      subtitle2: {
        color: colors.primary,
        fontWeight: 800,
        fontSize: '2rem'
      },
      caption: {
        // color: colors.gray,
      },
      body1: {
        // fontSize: '0.8rem',
      }
      
    },

    MuiFormControl: {
      root: {

      },
      marginNormal: {
        marginBottom: '10px',
        marginTop: '30px',
      }
    },

    MuiButton: {
      label: {
        paddingTop: '4px',
        paddingBottom: '4px',
        fontSize: '0.9rem',
        letterSpacing: '0.5px'
      }
    },

    MuiFormLabel: {
      root: {
        fontSize: '1.2rem',
        fontWeight: 700,
        position: 'absolute',
        top: '0px',
        left: '0px',
        color: colors.black,

        '& + .MuiInput-formControl': {
          marginTop: '30px'
        }
      }
    },

    MuiFormHelperText: {
      root: {
        color: 'red',
      }
    },

    MuiInputBase: {
      root: {
        
      }
    },

    MuiInputLabel: {
      shrink: {
        transform: 'none'
      }
    },

    MuiMenuItem: {
      root: {
        fontSize: '0.8rem',
        whiteSpace: 'none',
        paddingTop: '10px',
        paddingBottom: '10px',
        minHeight: 'auto',
        borderBottom: '1px solid #f3f3f3',
      }
    }

    












    // MuiFormLabel: {
    //   root: {
    //     color: '#000',
    //     '&$focused': {
    //       color: '#000',
    //     },
    //     '& + .MuiInput-formControl': {
    //       marginTop: 8,
    //     },
    //   },
    // },
    // MuiInputBase: {
    //   root: {
    //     fontSize: 14,
    //   },
    //   input: {
    //     padding: 0,
    //     height: 40,
    //   },
    // },
    // MuiInputLabel: {
    //   animated: {
    //     transform: 'none',
    //     position: 'static',
    //   },
    //   root: {
    //     fontSize: 18,
    //     scale: 1,
    //   },
    // },
  },
});

export default theme;
