import React, {useState, useContext, useEffect} from "react";
import { useForm, Controller } from "react-hook-form";

import urls from '@/services/urls';
import api from "@/services/api";

import { history } from "@/utils/history";

import { makeStyles } from '@material-ui/core/styles';

import { AppContext } from '@/contexts/AppContext';

import logo from "@/assets/images/logo.jpg";

import {
	Link,
  } from "react-router-dom";

import { 
	Box, 
	AppBar, 
	Toolbar, 
	TextField, 
	Typography,
	Button
} from '@material-ui/core';

import Loading from "@/components/Loading/Loading";

const LostPasswordEmail = ({
	setSendEmail,
	setEmail
}) => {
	const { handleSubmit, control, setError } = useForm();
	const { 
		setIsLoading,
		isLoading
	} = useContext(AppContext);
  	
	const onSubmit = (data) => {
		setIsLoading(true)

		api.post('user/forget', data)
			.then(() => {
				setIsLoading(false);
				setSendEmail(true) ;
				setEmail(data['email']);
			})
			.catch(() => {
				setIsLoading(false);
				setError("email", {
					type: "manual",
					message: "Podano błędne dane."
				});
				
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<Box 
				display="flex"
				height="100vh"
				flexDirection="column"
			>
				<AppBar position="static">
					<Toolbar>
						Przypomnij hasło
					</Toolbar>
				</AppBar>
				<Box 
					m={2} 
					flex="1"
					display="flex"
					flexDirection="column"
				>

					<Box
						flex="1" 
						marginTop={2}
						marginBottom={2} 
						display='flex'
						justifyContent='center'
						alignItems='center'
						flexDirection='column'
					>

						<Typography variant="subtitle2">
							NCU-AF
						</Typography>
						<Box 
							marginTop={2}
						>
							<img src={logo} alt="Logo"/>
						</Box>
						
					</Box>

					<Box>
						<Controller
							name="email"
							control={control}
							defaultValue=""
							rules={{ 
								required: 'To pole jest wymagane.',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "Podano nieprawidłowy adres email."
									}
							}}
							render={({ field, fieldState: { error } }) => <TextField
								label="Adres Email"
								
								placeholder="Podaj adres email"
								fullWidth
								margin="normal"
								helperText={error ? error.message : null}
								InputLabelProps={{
									shrink: true,
								}}
								{...field} 
								/>
							}
						/>

						

						<Box 
							marginTop={3}
							display="flex"
							flexDirection="column"
							alignItems="flex-end"
						>
							<Link to={urls.register}>
								Nie masz konta? Zarejestruj się!
							</Link>
						</Box>
						{isLoading && <Loading/>} 
						<Box marginTop={4}>
							<Button type="submit" fullWidth variant="contained" color="primary" disabled={isLoading}>
								Zresetuj hasło
							</Button>
						</Box>
						
					</Box>
					
					<Box 
						marginTop={3}
						flex="1"
						display='flex'
						justifyContent='space-between'
						alignItems="flex-end"
					>

						<Link to={urls.privacypolicy}>
							Polityka prywatności
						</Link>
						<Link to={urls.about}>
							O aplikacji
						</Link>
					</Box>

				</Box>
			</Box>
		</form>
	);
};

const LostPasswordPIN = ({
	setSendPIN,
	email
}) => {
	const { handleSubmit, control, setError, setValue } = useForm();
	const { 
		setIsLoading,
		isLoading
	} = useContext(AppContext);

	useEffect(() => {
		setValue('email', email);
	}, [email])

	const onSubmit = (data) => {
		setIsLoading(true)
		api.post('user/forget/reset', data)
			.then(() => {
				setIsLoading(false)
				setSendPIN(true);
			})
			.catch(() => {
				setIsLoading(false)
				setError("email", {
					type: "manual",
					message: "Podano błędne dane."
				});
				setError("pin", {
					type: "manual",
					message: "Podano błędne dane."
				});
				setError("password", {
					type: "manual",
					message: "Podano błędne dane."
				});
			});
	};
	
	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<Box 
				display="flex"
				height="100vh"
				flexDirection="column"
			>
				<AppBar position="static">
					<Toolbar>
						Przypomnij hasło
					</Toolbar>
				</AppBar>
				<Box 
					m={2} 
					flex="1"
					display="flex"
					flexDirection="column"
				>

					<Box
						flex="1" 
						marginTop={2}
						marginBottom={2} 
						display='flex'
						justifyContent='center'
						alignItems='center'
						flexDirection='column'
					>

						<Typography variant="subtitle2">
							NCU-AF
						</Typography>
						<Box 
							marginTop={2}
						>
							<img src={logo} alt="Logo"/>
						</Box>
						
					</Box>

					<Box>
						<Controller
							name="token"
							control={control}
							defaultValue=""
							rules={{ 
								required: 'To pole jest wymagane.'
							}}
							render={({ field, fieldState: { error } }) => <TextField
								label="PIN*"
								type="password"
								placeholder="Podaj PIN przesłany na adres email"
								fullWidth
								margin="normal"
								helperText={error ? error.message : null}
								InputLabelProps={{
									shrink: true,
								}}
								{...field} 
								/>
							}
						/>

						<Controller
							name="email"
							control={control}
							defaultValue=""
							rules={{ 
								required: 'To pole jest wymagane.',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "Podano nieprawidłowy adres email."
									}
							}}
							render={({ field, fieldState: { error } }) => <TextField
								label="Adres Email"
								placeholder="Podaj adres email"
								fullWidth
								margin="normal"
								helperText={error ? error.message : null}
								InputLabelProps={{
									shrink: true,
								}}
								{...field} 
								/>
							}
						/>

						<Controller
							name="password"
							control={control}
							defaultValue=""
							rules={{ 
								required: 'To pole jest wymagane.'
							}}
							render={({ field, fieldState: { error } }) => <TextField
								label="Hasło*"
								type="password"
								placeholder="Podaj hasło"
								fullWidth
								margin="normal"
								helperText={error ? error.message : null}
								InputLabelProps={{
									shrink: true,
								}}
								{...field} 
								/>
							}
						/>

						{isLoading && <Loading/>} 

					
						<Box marginTop={4}>
							<Button type="submit" fullWidth variant="contained" color="primary" disabled={isLoading}>
								Zresetuj hasło
							</Button>
						</Box>
						
					</Box>
					<Box 
							marginTop={3}
							flex="1"
							display='flex'
							justifyContent='space-between'
							alignItems="flex-end"
						>

							<Link to={urls.privacypolicy}>
								Polityka prywatności
							</Link>
							<Link to={urls.about}>
								O aplikacji
							</Link>
						</Box>

				</Box>
			</Box>
		</form>
	)
}

const LostPasswordChanged = () => {
	const useStyles = makeStyles(() => ({
		text: {
			fontSize: '1rem',
			lineHeight: '1.5rem',
			textAlign: 'center',
			fontWeight: 'bold'
		},
	}));
	const classes = useStyles();

	return (
		<Box 
				display="flex"
				height="100vh"
				flexDirection="column"
			>
				<AppBar position="static">
					<Toolbar>
						Przypomnij hasło
					</Toolbar>
				</AppBar>
				<Box 
					m={2} 
					flex="1"
					display="flex"
					flexDirection="column"
				>

					<Box
						flex="1" 
						marginTop={2}
						marginBottom={2} 
						display='flex'
						justifyContent='center'
						alignItems='center'
						flexDirection='column'
					>

						<Typography variant="subtitle2">
							NCU-AF
						</Typography>
						<Box 
							marginTop={2}
						>
							<img src={logo} alt="Logo"/>
						</Box>
						
					</Box>

					<Box
						flex="1"
					>
						<Typography 
							 className={ classes.text }
						>
							Hasło zostało zmienione.
						</Typography>
						
						
					</Box>
					<Box marginTop={4}>
						<Button onClick={() => history.push(urls.login)} fullWidth variant="contained" color="primary">
							Powrót do logowania
						</Button>
					</Box>
					<Box 
						marginTop={3}
						flex="1"
						display='flex'
						justifyContent='space-between'
						alignItems="flex-end"
					>

						<Link to={urls.privacypolicy}>
							Polityka prywatności
						</Link>
						<Link to={urls.about}>
							O aplikacji
						</Link>
					</Box>

				</Box>
			</Box>
	)
}

const LostPassword = () => {
	const [ sendEmail, setSendEmail ] = useState(false)
	const [ sendPIN, setSendPIN ] = useState(false)
	const [ email, setEmail ] = useState("")
	
	return (
		<>
			{ ( !sendEmail && !sendPIN ) && <LostPasswordEmail setSendEmail={setSendEmail} setEmail={setEmail}/> }
			{ ( sendEmail && !sendPIN ) && <LostPasswordPIN setSendPIN={setSendPIN} email={email}/>}
			{ ( sendEmail && sendPIN ) && <LostPasswordChanged />}
		</>
	)
}


export default LostPassword; 