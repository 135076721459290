import React, { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";

import { AppContext } from '@/contexts/AppContext';

import { 
	Box, 
	AppBar, 
	Toolbar, 
	TextField, 
	MenuItem, 
	Typography,
	Checkbox,
	Button,
	Grid,
	FormHelperText
} from '@material-ui/core';

import urls from "@/services/urls";
import api from "@/services/api";
import Loading from "@/components/Loading/Loading";

const Register = () => {
	let history = useHistory();

	const [institutions, setInstitutions] = useState([]);
	const { handleSubmit, control, formState: { errors } } = useForm();

	const { 
		setIsLoading,
		isLoading,
		token
	} = useContext(AppContext);

	useEffect(() => {
		if (token) history.push(urls.form);

		setIsLoading(true)
		api.get('institutions')
			.then(({data}) => {
				setInstitutions(data)
				setIsLoading(false)
			})
	}, [])


  	const onSubmit = data => {
		data['username'] = data.email;  
		
		setIsLoading(true)
		api.post('auth/register', data)
			.then(() => {
				setIsLoading(false);
				history.push({
					pathname: urls.login,
					state: { fromRegister: true }
				});
			})
			.catch(() => {
				setIsLoading(false);
			});
	  };

	if (isLoading) return (
		<Box 
			display="flex"
			height="100vh"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
		>
			<Loading/>
		</Box>
	)

	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<AppBar position="static">
				<Toolbar>
					Rejestracja
				</Toolbar>
			</AppBar>
			<Box m={2}>

				<Controller
					name="firstname"
					control={control}
					defaultValue=""
					rules={{ 
						required: 'To pole jest wymagane.',
					}}
					render={({ field, fieldState: { error } }) => <TextField
						label="Imię*"
						placeholder="Wpisz imię lekarza"
						fullWidth
						margin="normal"
						helperText={error ? error.message : null}
						InputLabelProps={{
							shrink: true,
						}}
						{...field} 
						/>
					}
				/>

				<Controller
					name="surname"
					control={control}
					defaultValue=""
					rules={{ 
						required: 'To pole jest wymagane.',
					}}
					render={({ field, fieldState: { error } }) => <TextField
						label="Nazwisko*"
						placeholder="Wpisz nazwisko lekarza"
						fullWidth
						margin="normal"
						helperText={error ? error.message : null}
						InputLabelProps={{
							shrink: true,
						}}
						{...field} 
						/>
					}
				/>

				<Controller
					name="pwz_number"
					control={control}
					defaultValue=""
					rules={{ 
						required: 'To pole jest wymagane.',
					}}
					render={({ field, fieldState: { error } }) => <TextField
						label="Numer PWZ*"
						placeholder="Podaj numer PWZ lekarza"
						fullWidth
						margin="normal"
						helperText={error ? error.message : null}
						InputLabelProps={{
							shrink: true,
						}}
						{...field} 
						/>
					}
				/>

				<Controller
					name="phone_number"
					control={control}
					defaultValue=""
					rules={{ 
						required: 'To pole jest wymagane.',
					}}
					render={({ field, fieldState: { error } }) => <TextField
						label="Numer telefonu*"
						placeholder="Podaj numer telefonu lekarza"
						fullWidth
						margin="normal"
						helperText={error ? error.message : null}
						InputLabelProps={{
							shrink: true,
						}}
						{...field} 
						/>
					}
				/>

				<Controller
					name="email"
					control={control}
					defaultValue=""
					rules={{ 
						required: 'To pole jest wymagane.',
						pattern: {
							value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
							message: "Podano nieprawidłowy adres email."
							}
					}}
					render={({ field, fieldState: { error } }) => <TextField
						label="Adres email*"
						
						placeholder="Podaj adres email lekarza"
						fullWidth
						margin="normal"
						helperText={error ? error.message : null}
						InputLabelProps={{
							shrink: true,
						}}
						{...field} 
						/>
					}
				/>

				<Controller
					name="password"
					control={control}
					defaultValue=""
					rules={{ 
						required: 'To pole jest wymagane.'
					}}
					render={({ field, fieldState: { error } }) => <TextField
						label="Hasło*"
						type="password"
						placeholder="Podaj hasło"
						fullWidth
						margin="normal"
						helperText={error ? error.message : null}
						InputLabelProps={{
							shrink: true,
						}}
						{...field} 
						/>
					}
				/>

				
				<Controller
					name="institution_id"
					control={control}
					defaultValue=""
					rules={{ 
						required: 'To pole jest wymagane.'
					}}
					render={({ field, fieldState: { error } }) => <TextField
						select
						label="Placówka*"
						placeholder="Podaj hasło"
						fullWidth
						margin="normal"
						helperText={error ? error.message : null}
						InputLabelProps={{
							shrink: true,
						}}
						{...field}
					>
						{institutions.map((institution) => (
							<MenuItem key={institution.id} value={institution.id}>
								{institution.name}
							</MenuItem>
						))}
					</TextField>
					}
				/>

				<Typography variant="caption" color='textSecondary'>* pola obowiązkowe</Typography>

				<Box marginTop={4}>
					<Grid container spacing={0}>
						<Grid item xs={2}>
							<Controller
								name="accept_regulations"
								control={control}
								defaultValue=""
								rules={{ 
									required: 'Należy zaakceptować to pole.',
								}}
								render={({ field }) => <Checkbox
									color="primary"
									inputProps={{ 'aria-label': 'secondary checkbox' }}
									{...field}
								/>
								}
							/>
							
						</Grid>
						<Grid item xs={10}>
							<Box marginTop={1}>
								<Typography variant="caption">
									<strong>Pole obowiązkowe</strong><br/>
									Wyrażam dobrowolnie świadomą zgodę na przetwarzanie moich danych osobowych w postaci: imienia, nazwiska, prawa wykonywania zawodu, numeru telefonu oraz adresu e-mail przez Szpital Uniwersytecki Nr 1 im. dr. A. Jurasza w Bydgoszczy w aplikacji mobilnej NCU – AF służącej do kwalifikacji do: zabiegu przezskórnego zamknięcia uszka lewego przedsionka; ablacji migotania przedsionków. Zapoznałam/em się z klauzulą informacyjną dla uczestników aplikacji mobilnej NCU - AF.
								</Typography>
							</Box>
						</Grid>
						{ (errors && errors.accept_regulations) && <FormHelperText>{errors.accept_regulations.message}</FormHelperText> }
					</Grid>
				</Box>
				<Box marginTop={5}>
					<Button type="submit" fullWidth variant="contained" color="primary">
						Zarejestruj
					</Button>
				</Box>
				<Box marginTop={2}>
					<Button onClick={() => { history.push(urls.about) }} fullWidth color="primary">
						O aplikacji
					</Button>
				</Box>

			</Box>
		</form>
	);
};


export default Register; 