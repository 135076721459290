import React, { useContext } from "react";

import { useForm, FormProvider } from "react-hook-form";

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@/utils/theme';

import { QualificationContext } from '@/contexts/QualificationContext';

import { 
	Box, 
	AppBar, 
	Toolbar, 
	Button,
	Grid,
} from '@material-ui/core';


const FormLayout = ({children, title, goBack, goNext}) => {
	const { 
		setAnswers,
		answers
	} = useContext(QualificationContext);

	const methods = useForm({
		defaultValues: answers
	});

	const useStyles = makeStyles(() => ({
		button: {
		  color: colors.primary,
		},
	}));
	const classes = useStyles();

	const onSubmit = (data) => {
		goNext();
		setAnswers(data)
	}

	return (
		<FormProvider {...methods} >
			<form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
				<Box 
					display="flex"
					height="100vh"
					flexDirection="column"
				>
					<AppBar position="static">
						<Toolbar>
							{title}
						</Toolbar>
					</AppBar>
					<Box 
						p={2} 
						flex="1"
						display="flex"
						flexDirection="column"
					>
						
						<Box
							flex="1" 
							marginTop={2}
							marginBottom={2} 
							display='flex'
							justifyContent='flex-start'
							alignItems='flex-start'
							flexDirection='column'
						>

							{ children }
							
						</Box>
					
						<Box>

							<Grid container spacing={1}>
								<Grid item xs={6}>
									<Button onClick={goBack} type="button" fullWidth variant="contained" color="secondary" className={ classes.button }>
										Wstecz
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button type="submit" fullWidth variant="contained" color="primary">
										Dalej
									</Button>
								</Grid>
							</Grid>
							
						</Box>
						
						
					</Box>
				</Box>
			</form>
		</FormProvider>
	);
}

export default FormLayout;