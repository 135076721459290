import React from "react";
import { useHistory } from "react-router-dom";

import { ArrowBack } from '@material-ui/icons';

import { 
	Box, 
	AppBar, 
	Toolbar, 
	IconButton
} from '@material-ui/core';

const StaticPage = ({title, children}) => {

	let history = useHistory();

	const prevButton = () => {
		history.goBack();
	}

	return (
		<Box >
			<AppBar position="static">
				<Toolbar>
					<IconButton onClick={prevButton} edge="start"  color="inherit" aria-label="menu">
						<ArrowBack />
					</IconButton>
					{title}
				</Toolbar>
			</AppBar>
			<Box m={2} >
				{children}
			</Box>
		</Box>
	);
};


export default StaticPage; 