import axios from 'axios';
// import localForage from 'localforage';

const apiAddress = 'https://ncu-af-dev.codaboo.com/';

const api = axios.create({
	baseURL: `${apiAddress}`,
	// withCredentials: true,
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json',
	},
	crossdomain: true,
});

// api.interceptors.request.use( config => {
// 	config.headers = localForage.getItem('token') && { 
// 		...config.headers, 
// 		Authorization: `Bearer ${localForage.getItem('token')}`
// 	};

// 	return config;
// }, function (error) {
// 	return Promise.reject(error);
// });

export default api;

/* ---
*   How to use api service:
*   - import api service into a file -> import 'services/api.js';
*   - GET method -> api.get('url').then((response) => ...).catch((error) => ...)
*   - POST method -> api.post('url', { data }).then((response) => ...).catch((error) => ...)
*   - PUT method -> api.put('url', { data }).then((response) => ...).catch((error) => ...)
*
*   Remember: PUT for UPDATE operations, POST for CREATE operations.
* ---
*/
