import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import { makeStyles } from '@material-ui/core/styles';

import FormLayout from '@/components/Form/parts/FormLayout';

import { 
	Box, 
	Typography,
	Grid,
	Checkbox,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormHelperText
} from '@material-ui/core';

const Checkboxes = ({question, answers, isInterview, handleChange}) => {
	const { control } = useFormContext();

	const useStyles = makeStyles(() => ({
		formControl: {
			alignItems: 'flex-start'
		},
		answer: {
			fontSize: '0.8rem',
			lineHeight: '1.25rem',
			margin: '12px 0 10px 12px'
		},
	}));
	const classes = useStyles();

	return (
		<>
			{answers.map((item) => {
				return (
					<Box key={item.id}>
						<Controller
							control={control}
							defaultValue=""
							name={`${isInterview ? 'interview.questions' : 'questions'}.qid-${question.id}.aid-${item.id}`}
							render={({field}) => <FormControlLabel 
									className={ classes.formControl }
									value={`${item.id}`}
									control={<Checkbox color="primary" {...field} checked={Boolean(field.value)} onChange={(event) => { field.onChange(event); handleChange(event, question, item) }} />}
									label={<Typography className={classes.answer}>{item.text}</Typography>}
									
								/>

							}
						/>
					</Box>
					
				)	
			})}
		</>
	)
}

const Booleans = ({question, answers, isInterview, handleChange}) => {
	const { control } = useFormContext();

	const useStyles = makeStyles(() => ({
		formControl: {
			alignItems: 'flex-start',

			'&:nth-child(1)': {
				color: '#45b829'
			},

			'&:nth-child(2)': {
				color: '#ff5a27'
			},

			'&:nth-child(3)': {
				color: '#b3b3b3'
			}
		},
		radioGroup: {
			flexDirection: 'row'
		},
		radioButton: {
			
			'& .MuiIconButton-label': {
				marginRight: '-10px',

				'& svg[class$="MuiSvgIcon-root"]': {
					opacity: 0
				}
				
				
				
			}
		
		},
		answer: {
			fontSize: '0.8rem',
			lineHeight: '1.25rem',
			fontWeight: '500',
			margin: '12px 10px 10px 0px',
			textTransform: 'uppercase'
		},
	}));
	const classes = useStyles();

	return (
		<Controller
			rules={{ required: true }}
			control={control}
			defaultValue=""
			name={`${isInterview ? 'interview.questions' : 'questions'}.qid-${question.id}`}
			render={({field, fieldState: { error }}) =>
				<>
					{ error && <FormHelperText>Należy zaznaczyć jedną odpowiedź</FormHelperText> }
					<RadioGroup {...field} className={ classes.radioGroup } >
						{answers.map((item) => <FormControlLabel 
									className={ classes.formControl }
									key={`${item.id}`}
									value={`aid-${item.id}`}
									
									control={<Radio color="primary" className={ classes.radioButton } onChange={(event) => { handleChange(event, question, item) }}/> }
									label={<Typography className={classes.answer}>{item.text}</Typography>}
								/>
							
						)}
					</RadioGroup>
				</>
			}
		/>
	)
}

const Radiobuttons = ({question, answers, isInterview, handleChange}) => {
	const { control } = useFormContext();

	const useStyles = makeStyles(() => ({
		formControl: {
			root: {
				alignItems: 'flex-start'
			}
		},
		answer: {
			fontSize: '0.8rem',
			lineHeight: '1.25rem',
			margin: '12px 0 10px 12px'
		},
	}));
	const classes = useStyles();

	return (
		<Controller
			rules={{ required: true }}
			control={control}
			defaultValue=""
			name={`${isInterview ? 'interview.questions' : 'questions'}.qid-${question.id}`}
			render={({field, fieldState: { error }}) =>
				<>
					{ error && <FormHelperText>Należy zaznaczyć jedną odpowiedź</FormHelperText> }
					<RadioGroup {...field}>
						{answers.map((item) => <FormControlLabel 
									className={ classes.formControl }
									key={`${item.id}`}
									value={`aid-${item.id}`}
									control={<Radio color="primary" onChange={(event) => { handleChange(event, question, item) }} />}
									label={<Typography className={classes.answer}>{item.text}</Typography>}
								/>
							
						)}
					</RadioGroup>
				</>
			}
		/>
	)
}

const Question = ({
	title, 
	questionNo,
	question,
	isInterview,
	goNext,
	goBack,
	handleChange
}) => {
	const useStyles = makeStyles(() => ({
		question: {
			fontSize: '1rem',
			lineHeight: '1.5rem',
			paddingBottom: '30px',
			minHeight: '120px',
		},
		questionNo: {
			fontSize: '0.8rem',
			color: '#fff',
			fontWeight: 500,
			background: '#d1d1d1',
			borderRadius: '100%',
			width: '24px',
			height: '24px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginLeft: '10px'
		},

		answers: {
			borderTop: '1px solid #e0e0e0',
			margin: '0 -16px',	
			padding: '16px',
			width: '100%'
		}
	}));
	const classes = useStyles();

	return (
		<FormLayout
			title={title}
			goNext={() => goNext(question)}
			goBack={goBack}
		>
			<Box flex="1" width="100%">
				<Grid container spacing={0}>
					<Grid item xs={2}>
						<Typography className={ classes.questionNo }>
							{ questionNo }
						</Typography>
					</Grid>
					<Grid item xs={10}>
						<Typography className={ classes.question }>
							{ question.text }:<br/>
							{ question.single_choice ? '(wybierz jedną odpowiedź)' : '(możesz wybrać kilka odpowiedzi)' }
						</Typography>
					</Grid>
				</Grid>
				
			</Box>
			
			<Box className={ classes.answers }>
				{ question.single_choice ? (
					<>
						{question.type === 'boolean' ? (
							<Booleans isInterview={isInterview} question={question} answers={question.answers} handleChange={handleChange}/>
						) : (
							<Radiobuttons isInterview={isInterview} question={question} answers={question.answers} handleChange={handleChange}/>
						)}

					</>
					
					
				) : (
					<Checkboxes isInterview={isInterview} question={question} answers={question.answers} handleChange={handleChange}/>
				)}
				
			</Box>
		</FormLayout>
	)
	
}

export default Question;