import React from "react";

import { CircularProgress, Box } from '@material-ui/core';

const Loading = () => {
	return (
		<Box 
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
		>
			<CircularProgress />
		</Box>
	)
};


export default Loading; 