import React from "react";
import StaticPage from '@/components/StaticPage/StaticPage'

import { 
	Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const About = () => {
	const useStyles = makeStyles(() => ({

		content: {
			fontSize: '1rem',
			'& li': {
				marginBottom: '15px'
			}
		},
	}));
	const classes = useStyles();

	return (
		<StaticPage title="O aplikacji">

			<Typography className={ classes.content}>
			Nicolaus Copernicus University - Atrial Fibrilation (NCU-AF) jest przeznaczoną dla lekarzy aplikacją na urządzenia mobilne, która ma na celu:
			<ol>
				<li>ułatwienie wyboru strategii leczenia migotania przedsionków (strategia zachowawcza vs. inwazyjna),</li>
				<li>ułatwienie kwalifikacji pacjentów do ablacji migotania przedsionków oraz przezskórnego zamykania uszka lewego przedsionków,</li>
				<li>umożliwienie zgłaszania pacjentów do leczenia inwazyjnego migotania przedsionków bezpośrednio do ośrodka wykonującego ablacje migotania przedsionków oraz przezskórne zamykanie uszka lewego przedsionka (Klinika Kardiologii, Szpital Uniwersytecki nr 1 im. dr Antoniego Jurasza, w Bydgoszczy),</li>
				<li>skrócenie całkowitego czasu oczekiwania na zabieg.</li>
			</ol>

Lekarz zgłaszający wypełnia elektroniczny formularz kwalifikacyjny wybranej procedury, a bezpośrednio po udzieleniu odpowiedzi na wszystkie wymagane pytania (ablacja migotania przedsionków - 6 pytań lub przezskórne zamykanie uszka lewego przedsionka - 10 pytań) uzyskuje wynik kwalifikacji do wybranego zabiegu. Możliwe do uzyskania wyniki kwalifikacji:
<ol>
	<li>pacjent posiada wskazania do zabiegu,</li>
	<li>pacjent nie posiada wskazań lub posiada przeciwwskazania do zabiegu,</li>
	<li>pacjent wymaga kwalifikacji indywidualnej.</li>
</ol>
W przypadku posiadania przez pacjenta wskazań do zabiegu lub gdy pacjent będzie wymagał kwalifikacji indywidualnej możliwe będzie jednoczasowe zgłoszenie pacjenta drogą elektroniczną bezpośrednio do ośrodka wykonującego w/w zabiegi. 


			</Typography>
		</StaticPage>
	);
};


export default About; 