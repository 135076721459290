import React, { useState, useEffect, useContext } from "react";

import { useForm, FormProvider, Controller, useFormContext } from "react-hook-form";

import { makeStyles } from '@material-ui/core/styles';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { QualificationContext } from '@/contexts/QualificationContext';

import { 
	Box, 
	AppBar, 
	Toolbar, 
	TextField, 
	MenuItem, 
	Typography,
	Button,
} from '@material-ui/core';

import Loading from "@/components/Loading/Loading";


const PatientInfo = ({setRodoActive}) => {
	const { control, watch } = useFormContext();

	const [ old, setOld ] = useState('')

	const birthday = watch('patient.birthday');

	useEffect(() => {
		if (birthday) setOld(calculateAge())
	}, [birthday])

	const calculateAge = () => { 
		let ageDifMs = Date.now() - new Date(birthday);
		let ageDate = new Date(ageDifMs); 
		return Math.abs(ageDate.getUTCFullYear() - 1970);
	}

	return (
		<Box 
			display="flex"
			flexDirection="column"
			height="100vh"
		>
			<AppBar position="static">
				<Toolbar>
					Dane pacjenta
				</Toolbar>
			</AppBar>
			<Box 
				p={2} 
				flex="1"
				display="flex"
				flexDirection="column"
			>
				<Box flex="1">

					<Controller
						name="patient.firstname"
						control={control}
						defaultValue=""
						rules={{ 
							required: 'To pole jest wymagane.',
						}}
						render={({ field, fieldState: { error } }) => <TextField
							label="Imię*"
							placeholder="Wpisz imię pacjenta"
							fullWidth
							margin="normal"
							helperText={error ? error.message : null}
							InputLabelProps={{
								shrink: true,
							}}
							{...field} 
							/>
						}
					/>

					<Controller
						name="patient.surname"
						control={control}
						defaultValue=""
						rules={{ 
							required: 'To pole jest wymagane.',
						}}
						render={({ field, fieldState: { error } }) => <TextField
							label="Nazwisko*"
							placeholder="Wpisz nazwisko pacjenta"
							fullWidth
							margin="normal"
							helperText={error ? error.message : null}
							InputLabelProps={{
								shrink: true,
							}}
							{...field} 
							/>
						}
					/>

					<Controller
						name="patient.sex"
						control={control}
						defaultValue=""
						rules={{ 
							required: 'To pole jest wymagane.'
						}}
						render={({ field, fieldState: { error } }) => <TextField
							select
							label="Płeć*"
							placeholder="Podaj płeć pacjenta"
							fullWidth
							margin="normal"
							helperText={error ? error.message : null}
							InputLabelProps={{
								shrink: true,
							}}
							{...field}
						>
							<MenuItem value="male">
								mężczyzna
							</MenuItem>
							<MenuItem value="female">
								kobieta
							</MenuItem>
						</TextField>
						}
					/>

					<Controller
						name="patient.birthday"
						control={control}
						defaultValue=""
						
						rules={{ 
							required: 'To pole jest wymagane.',
						}}
						render={({ field, fieldState: { error } }) => <TextField
							label="Data urodzenia*"
							placeholder="Podaj datę urodzenia pacjenta"
							fullWidth
							type="date"
							margin="normal"
							helperText={error ? error.message : null}
							InputLabelProps={{
								shrink: true,
							}}
							{...field} 
							/>
						}
					/>

					<TextField
							label="Wiek"
							placeholder="Wiek pacjenta"
							fullWidth
							margin="normal"
							value={old}

							InputLabelProps={{
								shrink: true,
								readOnly: true,
							}}
							/>

					<Controller
						name="patient.phone_number"
						control={control}
						defaultValue=""
						rules={{ 
							required: 'To pole jest wymagane.',
						}}
						render={({ field, fieldState: { error } }) => <TextField
							label="Numer telefonu*"
							placeholder="Podaj numer telefonu pacjenta"
							fullWidth
							margin="normal"
							helperText={error ? error.message : null}
							InputLabelProps={{
								shrink: true,
							}}
							{...field} 
							/>
						}
					/>

					<Controller
						name="patient.email"
						control={control}
						defaultValue=""
						rules={{ 
							pattern: {
								value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
								message: "Podano nieprawidłowy adres email."
								}
						}}
						render={({ field, fieldState: { error } }) => <TextField
							label="Adres email"
							
							placeholder="Podaj adres email pacjenta"
							fullWidth
							margin="normal"
							helperText={error ? error.message : null}
							InputLabelProps={{
								shrink: true,
							}}
							{...field} 
							/>
						}
					/>
						<Typography variant="caption" color='textSecondary'>* pola obowiązkowe</Typography>
					</Box>
					<Box marginTop={2}>
						<Button onClick={() => setRodoActive(true)} fullWidth variant="contained" color="primary">
							Zapisz i kontynuuj
						</Button>
					</Box>

			</Box>
		</Box>
	)
}

const PatientRodo = () => {
	const { register, setValue, watch } = useFormContext();

	const useStyles = makeStyles(() => ({
		button: {
			marginTop: '16px',
		},

		text: {
			color: '#aaaaaa',
			fontSize: '2rem',
			lineHeight: '3rem',
			textAlign: 'center',
			maxWidth: '70%',
			margin: '25px auto'
		},

		added: {
			color: '#45b829',
			fontSize: '1.5rem',
			lineHeight: '2.5rem',
			fontWeight: 'bold',
			textAlign: 'center',
			maxWidth: '70%',
			margin: '25px auto'
		},

		input: {
			display: 'none',
		},
		
		svg: {
			width: '150px',
			height: '150px',
			color: '#45b829',
		}
	}));
	const classes = useStyles();

	const [ fileLoading, setFileLoading ] = useState(false);
	const rodo = watch('patient.rodo');
	const uploadedFile = watch('uploadedFile');

	const toBase64 = file => new Promise((resolve, reject) => {
		setFileLoading(true);
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => { setValue('patient.rodo', reader.result); setFileLoading(false); };
		reader.onerror = error => reject(error);
	});

	async function getFile() {
		await toBase64(uploadedFile[0]);
	 }

	useEffect(() => {
		if (uploadedFile) {
			if (uploadedFile.length) getFile();
		}
	}, [uploadedFile])

	return (
		<Box 
			display="flex"
			flexDirection="column"
			height="100vh"
		>
			<AppBar position="static">
				<Toolbar>
					RODO pacjenta
				</Toolbar>
			</AppBar>
			<Box 
				p={2} 
				flex="1"
				display="flex"
				flexDirection="column"
			>
				<Box flex="1" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
					{fileLoading ? ( 
						<Loading/>
					):(
						<>
						<Box width="100%">
							{ !rodo ? ( 
							<Typography className={classes.text}>
								Dodaj zdjęcie dokumentu z galerii w telefonie, lub zrób zdjęcie.
							</Typography>
							) : (
								<Box 
									width="100%"
									display="flex"
									flexDirection="column"
									alignItems="center"
								>	
									<CheckCircleOutlineIcon className={classes.svg}/>
									<Typography className={classes.added}>
										Dodano zdjęcie
									</Typography>
							
								</Box>
							)}
						</Box>		
						<Box marginTop={2} width="100%">
							<input
								type="hidden"
								defaultValue=''
								{...register("patient.rodo", { required: true })}
							/>
							<input
								accept="image/*"
								className={classes.input}
								id="contained-button-file"
								type="file"
								{...register("uploadedFile", { required: true })}
							/>
							<label htmlFor="contained-button-file">
								{!rodo && <Button fullWidth variant="contained" color="primary" className={ classes.button } component="span">
									Dodaj zdjęcie
								</Button>}
							</label>

						</Box>
						</>
					) 
					
					}

				</Box>
				<Box marginTop={2}>
					<Button type="submit" fullWidth variant="contained" color="primary" disabled={!rodo}>
						Zapisz i zakończ
					</Button>
				</Box>

			</Box>
		</Box>
	)
}

const Patient = ({setPatient}) => {

	const methods = useForm();

	const [ rodoActive, setRodoActive ] = useState(false);

	const { 
		sendQualification
	} = useContext(QualificationContext);

  	const onSubmit = data => {
		delete data['uploadedFile'];
		setPatient(data);

		sendQualification('file');
	};

	return (
		<FormProvider {...methods} >
			<form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
				{!rodoActive ? (
					<PatientInfo setRodoActive={setRodoActive} />
				) : (
					<PatientRodo />
				)}
			</form>
		</FormProvider>
	);
};


export default Patient; 