import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import urls from "@/services/urls";
import { AppContext } from '@/contexts/AppContext';

import { Box, Typography } from '@material-ui/core';

import logo from "@/assets/images/logo.jpg";

const Home = () => {
	let history = useHistory();
	const { 
		token,
	} = useContext(AppContext);


	const url = token ? urls.form : urls.login;

	useEffect(() => {
		setTimeout(() => { history.push(url) }, 3000);
	}, [ history, url ]);

	return (
		<Box 
			height='100vh'
			display='flex'
			flexDirection='column'
			alignItems='center'
			justifyContent='center'
		>
			<Box 
				flex="1"
				display='flex'
				flexDirection='column'
				alignItems='center'
				justifyContent='center'
			>
				<Typography variant="subtitle1">
					Witamy w aplikacji
				</Typography>
				<Typography variant="subtitle2">
					NCU-AF
				</Typography>
			</Box>
			<Box 
				m={5}
			>
				<img src={logo} alt="Logo"/>
			</Box>
		</Box>
	)
};


export default Home; 