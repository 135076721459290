import React, { useContext } from "react";

import { makeStyles } from '@material-ui/core/styles';

import { QualificationContext } from '@/contexts/QualificationContext';

import { 
	Box, 
	AppBar, 
	Toolbar, 
	Button,
	Grid,
} from '@material-ui/core';

const ChooseQualification = () => {
	const useStyles = makeStyles(() => ({
		button: {
		  textTransform: 'none',
		  height: '100%',
		  padding: '30px 10px',
		  lineHeight: '1.5rem'
		},
	}));
	const classes = useStyles();

	const { 
		qualifications,
		setQualificationId
	} = useContext(QualificationContext);
	
	return (
		<>
			<AppBar position="static">
				<Toolbar>
					Wybierz kwalifikację
				</Toolbar>
			</AppBar>
			<Box m={1} marginTop={3}>

				{ qualifications && 
				<Grid container spacing={1}>
					{	qualifications.map((item) => {
							return (
								<Grid item xs={6} key={item.id}>
									<Button onClick={() => setQualificationId(item.id)} fullWidth variant="contained" color="primary" className={ classes.button }>
										Kwalifikacja do {item.name}
									</Button>
								</Grid>
							)

						})

					}
				</Grid>
				}

			</Box>
		</>
	);
}

export default ChooseQualification;