import React from "react";
import StaticPage from '@/components/StaticPage/StaticPage'

import { 
	Typography,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const PrivacyPolicy = () => {

	const useStyles = makeStyles(() => ({
		title: {
			fontSize: '1rem',
			lineHeight: '1.5rem',
			paddingBottom: '30px',
			fontWeight: 'bold'
		},

		content: {
			fontSize: '1rem',
			'& li': {
				marginBottom: '15px'
			}
		},
	}));
	const classes = useStyles();

	return (
		<StaticPage title="Polityka prywatności">
			<Typography className={ classes.title}>
				KLAUZULA INFORMACYJNA DLA UCZESTNIKÓW APLIKACJI MOBILNEJ NCU - AF
			</Typography>
			<Typography className={ classes.content}>
				Zgodnie z art. 13 ust. 1 i ust. 2 ogólnego rozporządzenia RODO informuję, iż:
				<ol>
					<li>Administratorem Państwa danych osobowych jest Szpital Uniwersytecki nr1 im. dr. Antoniego Jurasza  w Bydgoszczy. Kontakt z Administratorem Danych: 52 585 48 80 lub wysyłając pisemną korespondencję na adres: Szpital Uniwersytecki Nr1 im. dr. Antoniego Jurasza w Bydgoszczy ul. Marii Skłodowskiej – Curie  9, 85 – 094 Bydgoszcz lub na  e- mail: <a href="mailto: sekretariat@.jurasza.pl">sekretariat@jurasza.pl</a></li>
					<li>Kontakt z Inspektorem Ochrony Danych (IOD): e-mail  <a href="mailto: je.nowak@jurasza.pl">je.nowak@jurasza.pl</a> lub wysyłając pisemną korespondencję na adres Administratora Danych z dopiskiem „IOD”.</li>
					<li>Państwa dane przetwarzane są w celu ochrony stanu zdrowia, świadczenia usług medycznych, zarządzania udzielaniem tych usług oraz leczenia Państwa. Podstawą prawną przetwarzania pozyskanych danych są ustawy z dnia 15 kwietnia 2011 r. o działalności leczniczej wraz z przepisami wykonawczymi wydanymi na ich podstawie oraz ustawa z dnia 6 listopada 2008 r. o prawach pacjenta    i Rzeczniku Praw Pacjenta. Ponadto dane przetwarzane będą w związku z wyrażoną, na podstawie art. 6 ust. 1 lit. a) ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r., zgodą na przetwarzanie przez Administratora danych osobowych do kwalifikacji do zabiegu przezskórnego zamknięcia uszka lewego przedsionka lub ablacji migotania przedsionków . W momencie udziału w kwalifikacji dane będą przetwarzane na podstawie art. 6 ust. 1 lit. c) ogólnego rozporządzenia o ochronie danych osobowych.</li>
					<li>Dane osobowe przetwarzane są wyłącznie w zakresie związanym z realizacją powyższych celów.  </li>
					<li>Administrator nie zamierza przekazywać danych osobowych do państwa trzeciego ani do organizacji międzynarodowych.</li>
					<li>Pana/Pani dane osobowe przechowywane będą w okresie niezbędnym do realizacji celów przetwarzania tj. do czasu zakończenia kwalifikacji do zabiegów opisanych w punkcie 3.</li>
					<li>Mają Państwo prawo żądać od Administratora dostępu do swoich danych, ich sprostowania, zaktualizowania, jak również prawo do ograniczenia przetwarzania danych. </li>
					<li>W związku z przetwarzaniem danych osobowych przez Administratora przysługuje Państwo prawo wniesienia skargi do organu nadzorczego Prezesa Ochrony Danych Osobowych.</li>
					<li>Podanie danych osobowych jest dobrowolne jednak odmowa ich podania uniemożliwi Pani/Panu obsługę w aplikacji mobilnej NCU – AF w ramach organizacji i realizacji kwalifikacji do zabiegów opisanych w punkcie 3.</li>
					<li>Dysponując danymi osobowymi Administrator nie będzie podejmował wobec Państwa zautomatyzowanych decyzji, w tym decyzji będących wynikiem profilowania.</li>
				</ol>
			</Typography>
		</StaticPage>
	);
};


export default PrivacyPolicy; 