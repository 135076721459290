import React, {useState, useEffect, useContext} from "react";
import { Router, Switch } from "react-router-dom";
import localForage from 'localforage';

import { Container } from '@material-ui/core';

import routes from "@/services/routes";
import urls from '@/services/urls';
import { history } from '@/utils/history';

import AppContextProvider, { AppContext } from '@/contexts/AppContext';

import Loading from "@/components/Loading/Loading";

const AppRoute = ({isProtected, component: Component}) => {

	const { 
		token,
		isChecking,
	} = useContext(AppContext);
	
	useEffect(() => {
		if (isChecking) {
			if (isProtected && !token) { 
				history.push(urls.login);
			};
		};
	}, [ isChecking ])

	if (!isChecking) return <Loading/>;

	return (
		<Container maxWidth="sm">
			<Component/>
		</Container>
	);
}

const App = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isChecking, setIsChecking ] = useState(false);
	const [ token, setToken ] = useState(null);

	useEffect(() => {
		localForage.getItem('token').then(token => { 
			setToken(token);
			setIsChecking(true);
		})
	}, [])
	
	return (
		<AppContextProvider 
			isLoading={ isLoading } 
			setIsLoading={ setIsLoading }
			token={ token }
			setToken={ setToken }
			isChecking={ isChecking }
		>
			<Router history={history}>
				<Switch>
					{routes.map((route) => (
						<AppRoute key={route.id} {...route}/>
					))}
				</Switch>
			</Router> 
		</AppContextProvider>
	);
};


export default App; 