import React, { createContext } from 'react';

export const QualificationContext = createContext();

const QualificationContextProvider = (props) => {
	return (
		<QualificationContext.Provider value={ {...props} }>
			{ props.children }
		</QualificationContext.Provider>
	);
};


export default QualificationContextProvider;