const urls = {
	home: "/",
	login: "/login",
	register: "/register",
	lostpassword: "/lostpassword",
	regulations: "/regulations",
	privacypolicy: "/privacypolicy",
	about: "/about",
	form: "/form",
};

export default urls
