import React from "react";
import StaticPage from '@/components/StaticPage/StaticPage'

const Regulations = () => {

	return (
		<StaticPage title="Regulamin">
			asd
		</StaticPage>
	);
};


export default Regulations; 