import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useLocation } from "react-router-dom";
import localForage from 'localforage';

import urls from '@/services/urls';
import api from "@/services/api";

import { history } from "@/utils/history";

import { AppContext } from '@/contexts/AppContext';
import { makeStyles } from '@material-ui/core/styles';


import {
	Link,
  } from "react-router-dom";

import logo from "@/assets/images/logo.jpg";

import { 
	Box, 
	AppBar, 
	Toolbar, 
	TextField, 
	Typography,
	Button
} from '@material-ui/core';
import Loading from "@/components/Loading/Loading";

const Login = () => {
	const { handleSubmit, control, setError } = useForm();
	const { 
		setIsLoading,
		isLoading, 
		token,
		setToken,
	} = useContext(AppContext);
	const location = useLocation();

	useEffect(() => {
		if (token) history.push(urls.form);
	}, []);

  	const onSubmit = (data) => {
		setIsLoading(true)

		api.post('auth/login_check', data)
			.then(({data: LoginData}) => {
				localForage.setItem('token', LoginData.token);
				setToken(LoginData.token)

				api.get('api/v1/qualifications', { headers: {
					Authorization: `Bearer ${LoginData.token}`
				}})
					.then(({data: QualificationData}) => {
						localForage.setItem('qualifications', QualificationData).then(() => {
							history.push(urls.form);
							setIsLoading(false);
						});
					})
					.catch(() => {
						setIsLoading(false);

						setError("username", {
							type: "manual",
							message: "Wystąpił błąd. Spróboj ponownie."
						});
					})

				
			})
			.catch(() => {
				setIsLoading(false);
				setError("username", {
					type: "manual",
					message: "Podano błędne dane."
				});
				setError("password", {
					type: "manual",
					message: "Podano błędne dane."
				});
			});
	};

	const useStyles = makeStyles(() => ({
		text: {
			fontSize: '1rem',
			lineHeight: '1.5rem',
			textAlign: 'center',
			fontWeight: 'bold'
		},
	}));
	const classes = useStyles();

	return (
		<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
			<Box 
				display="flex"
				height="100vh"
				flexDirection="column"
			>
				<AppBar position="static">
					<Toolbar>
						Logowanie
					</Toolbar>
				</AppBar>
				<Box 
					m={2} 
					flex="1"
					display="flex"
					flexDirection="column"
				>
					
					<Box
						flex="1" 
						marginTop={2}
						marginBottom={2} 
						display='flex'
						justifyContent='center'
						alignItems='center'
						flexDirection='column'
					>

						<Typography variant="subtitle2">
							NCU-AF
						</Typography>
						<Box 
							marginTop={2}
						>
							<img src={logo} alt="Logo"/>
						</Box>
						
					</Box>


					{ (location.state && location.state.fromRegister) && <Box
						flex="1"
					>
						<Typography 
							 className={ classes.text }
						>
							Konto zostało utworzone. Możesz teraz się zalogować.
						</Typography>
						
						
					</Box>}
				
					<Box>

						<Controller
							name="username"
							control={control}
							defaultValue=""
							rules={{ 
								required: 'To pole jest wymagane.',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: "Podano nieprawidłowy adres email."
									}
							}}
							render={({ field, fieldState: { error } }) => <TextField
								label="Adres Email"
								
								placeholder="Podaj adres email"
								fullWidth
								margin="normal"
								helperText={error ? error.message : null}
								InputLabelProps={{
									shrink: true,
								}}
								{...field} 
								/>
							}
						/>

						<Controller
							name="password"
							control={control}
							defaultValue=""
							rules={{ 
								required: 'To pole jest wymagane.'
							}}
							render={({ field, fieldState: { error } }) => <TextField
								label="Hasło"
								type="password"
								placeholder="Podaj hasło"
								fullWidth
								margin="normal"
								helperText={error ? error.message : null}
								InputLabelProps={{
									shrink: true,
								}}
								{...field} 
								/>
							}
						/>

						<Box 
							marginTop={3}
							display="flex"
							flexDirection="column"
							alignItems="flex-end"
						>
							<Link to={urls.lostpassword}>
								Zresetuj hasło
							</Link>
							
						</Box>
						<Box 
							marginTop={3}
							display="flex"
							flexDirection="column"
							alignItems="flex-end"
						>
							<Link to={urls.register}>
								Nie masz konta? Zarejestruj się!
							</Link>
						</Box>
						{isLoading && <Loading/>} 
						<Box marginTop={4}>
							<Button type="submit" fullWidth variant="contained" color="primary" disabled={isLoading}>
								Zaloguj
							</Button>
						</Box>
						
					</Box>
					<Box 
						marginTop={3}
						flex="1"
						display='flex'
						justifyContent='space-between'
						alignItems="flex-end"
					>

						<Link to={urls.privacypolicy}>
							Polityka prywatności
						</Link>
						<Link to={urls.about}>
							O aplikacji
						</Link>
					</Box>
					
				</Box>
			</Box>
		</form>
	);
};


export default Login; 