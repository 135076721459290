import React, { useContext } from "react";

import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@/utils/theme';

import { QualificationContext } from '@/contexts/QualificationContext';

import { 
	Box, 
	AppBar, 
	Toolbar, 
	Typography,
	Button,
	Grid,
} from '@material-ui/core';

const Result = ({status, points, title}) => {
	const statusText = {
		'classified': 'Zakwalifikowany',
		'rejected': 'Odrzucony',
		'individual': 'Do kwalifikacji indywidualnej',
	}
	const statusColor = {
		'classified': '#53bd39',
		'rejected': '#ff6d3f',
		'individual': '#ffd567',
	};

	const useStyles = makeStyles(() => ({
		button: {
		  color: colors.primary,
		  marginTop: '16px',
		},

		heading: {
			fontSize: '1.2rem',
			textAlign: 'center'
		},

		label: {
			fontSize: '0.9rem',
			color: '#b9b9b9',
			textAlign: 'center'
		},

		value: {
			fontSize: '1rem',
			color: '#000',
			textAlign: 'center',
			fontWeight: '500',
			marginTop: '10px'
		},

		isColor: {
			color: statusColor[status],
			fontWeight: "500",
		},

		box: {
			borderTop: '1px solid #ccc',
			margin: '1rem -16px 0',
			padding: '2rem 16px 1.5rem',
		},

		bottom: {
			padding: '16px',
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'flex-end',
			backgroundColor: (status === 'classified' || status === 'individual') && '#fff8dc',
		},

		bottomInside: {
			marginBottom: '16px',
			flex: 1,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center'
		},

		patient: {
			fontSize: '1rem',
			color: '#000',
			textAlign: 'center',
			fontWeight: '300',
		},

		qualification: {
			fontSize: '1rem',
			color: '#000',
			textAlign: 'center',
			fontWeight: '500',
			textTransform: 'uppercase'
		},
	}));
	const classes = useStyles();

	const { 
		sendQualification,
		continueQualification
	} = useContext(QualificationContext);

	const sum = Object.values(points)
		.map(item => Object.values(item.value).reduce((a, b) => a + b, 0))
		.reduce((prev, curr) => prev + curr, 0);

	return (
		<Box
			display="flex"
			height="100vh"
			flexDirection="column"
		>
			<AppBar position="static">
				<Toolbar>
					Wynik ankiety
				</Toolbar>
			</AppBar>
			<Box p={2} borderBottom='1px solid #ccc'>

				<Box>
					<Typography className={ classes.heading }>
						Wynik ankiety
					</Typography>
					<Typography className={` ${classes.heading} ${classes.isColor}` }>
						{statusText[status]}
					</Typography>
				</Box>
				{!!Object.values(points).length && <Box className={ classes.box }>
					<Box>
						<Grid container spacing={1}>
							{ Object.values(points).map((item) => {
								let sumValues = Object.values(item.value).reduce((a, b) => a + b, 0)
								return (
									<Grid item xs={6} key={item.title}>
										<Typography className={ classes.label }>
											{item.title}
										</Typography>
										<Typography className={ classes.value }>
											{sumValues} pkt
										</Typography>
									</Grid>
								)
							})}
						</Grid>
					</Box>
				</Box>}
			</Box>
			{(status === 'classified' || status === 'individual') && <Box className={ classes.bottom }>
				<Box className={ classes.bottomInside }>
					<Typography className={ classes.patient }>
						Czy pacjent wyraża zgodę na dalszą kwalifikację do
					</Typography>
					<Typography className={ classes.qualification }>
						{title}
					</Typography>
				</Box>
				<Box>
					<Button onClick={continueQualification} fullWidth variant="contained" color="primary">
						Tak ( kontynuuj )
					</Button>
					<Button onClick={sendQualification} fullWidth variant="contained" color="secondary" className={ classes.button }>
						Nie (zapisz i zakończ)
					</Button>
				</Box>
			</Box>}
			{(status === 'rejected') && <Box className={ classes.bottom }>
				<Box>
					<Button onClick={sendQualification} fullWidth variant="contained" color="primary">
						Zapisz i zakończ
					</Button>
				</Box>
			</Box>}
		</Box>
	
	)
}

export default Result; 