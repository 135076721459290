import url from "./urls";

import Home from "@/pages/Home/Home";

import Login from "@/pages/Login/Login";
import Register from "@/pages/Register/Register";
import LostPassword from "@/pages/LostPassword/LostPassword";

import Regulations from "@/pages/Regulations/Regulations";
import PrivacyPolicy from "@/pages/PrivacyPolicy/PrivacyPolicy";
import About from "@/pages/About/About";

import Form from "@/pages/Form/Form";

const routes = [
	{
		id: "home",
		path: url.home,
		exact: true,
		isProtected: false,
		component: Home,
	},

	{
		id: "login",
		path: url.login,
		exact: true,
		isProtected: false,
		component: Login,
	},
	{
		id: "register",
		path: url.register,
		exact: true,
		isProtected: false,
		component: Register,
	},
	{
		id: "lostpassword",
		path: url.lostpassword,
		exact: true,
		isProtected: false,
		component: LostPassword,
	},

	{
		id: "regulations",
		path: url.regulations,
		exact: true,
		isProtected: false,
		component: Regulations,
	},
	{
		id: "privacypolicy",
		path: url.privacypolicy,
		exact: true,
		isProtected: false,
		component: PrivacyPolicy,
	},
	{
		id: "about",
		path: url.about,
		exact: true,
		isProtected: false,
		component: About,
	},

	{
		id: "form",
		path: url.form,
		exact: true,
		isProtected: true,
		component: Form,
	},
]; 

export default routes;